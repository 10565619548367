<template>
  <div style="padding: 20px" v-loading="loading">
    <div>费率说明</div>
    <el-image
      style="width: 200px; height: 200px; margin: 20px"
      :src="rate"
      :fit="fits"
      :preview-src-list="rates"
    ></el-image>
    <!-- 上传图片 -->
    <el-upload
      class="avatar-uploader"
      action=""
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :before-upload="beforeAvatarUpload"
      :http-request="mainPicUpload"
    >
      <el-button size="small" type="primary">点击修改</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
  data() {
    return {
      rate: "",
      fits: "scale-down",
      rates: [],
      loading: false,
    };
  },
  created() {
    //   获取图片
    this.loadingPictures();
  },
  methods: {
    //   获取图片
    loadingPictures() {
      this.axios
        .get("/admin/rate/showRate", {})
        .then((res) => {
          if (res.data.code == 200) {
            this.rate = res.data.data.image;
            this.rates.push(res.data.data.image);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //   点击修改图片
    mainPicUpload(options) {
      this.loading = true;
      function dataUrl(data) {
        let arr = data.split("&");
        let list = {};
        arr.forEach((item) => {
          let itm = item.split("=");
          list[itm[0]] = decodeURIComponent(itm[1]);
        });
        return list;
      }
      var { file } = options;
      let data;
      this.axios.get("/getOSSKey", {}).then((res) => {
        data = res.data.data;
        var ossData = new FormData();
        ossData.append("name", file.name);
        let filename = `${Date.parse(new Date())}` + file.name; //图片名称
        let keyValue = data.dir + filename;
        //请求数据
        ossData.append("key", keyValue);
        ossData.append("callback", data.callback);
        ossData.append("policy", data.policy);
        ossData.append("OSSAccessKeyId", data.accessid);
        ossData.append("success_action_status", 200);
        ossData.append("signature", data.signature);
        ossData.append("file", file);
        this.axios.post(data.host, ossData).then((res) => {
          this.rate = dataUrl(res.data.Status).filename;
          this.axios
            .get("/admin/rate/updateRate", {
              image: this.rate,
            })
            .then((res) => {
              if (res.data.code == 200) {
                this.loadingPictures();
                this.loading = false;
                this.$message({
                  message: res.data.mesg,
                  type: "success",
                });
              } else {
                this.loading = false;
                this.$message({
                  message: res.data.mesg,
                  type: "error",
                });
              }
            })
            .catch((err) => {
              this.loading = false;
            });
        });
      });
    },
    handleAvatarSuccess(res, file) {
      //   console.log(res, file);
      //   // this.imageUrl = URL.createObjectURL(file.raw);
      //   console.log(this.imageUrl);
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 4;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
  },
};
</script>

<style lang="scss" scoped></style>
